import React from "react";
import { graphql } from "gatsby";
import { SitemapStyles } from "../styles/PageSitemap";
import {
  Container,
  ConvertedMarkdown,
  InterstitialInjector,
  ISIToggleInjector,
} from "../components";

const Sitemap = ({ data }) => {
  const dataQuery = data.allMarkdownRemark.nodes[0],
    componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
    markdownHTML = data.allMarkdownRemark.nodes[0].html,
    interstitials = data.allMarkdownRemark.nodes[0].frontmatter.interstitials,
    markdownID = `sitemap-content`;

  return (
    <>
      <Container
        markdownID={markdownID}
        componentID={componentID}
        query={dataQuery}
        noSubNav={true}
      >
        <SitemapStyles id={`main`} className={`wrapper`}>
          <ConvertedMarkdown markdownID={markdownID} mdhtml={markdownHTML} />
          <InterstitialInjector query={interstitials} />
          <ISIToggleInjector query={dataQuery.frontmatter.isiToggle} />
        </SitemapStyles>
      </Container>
    </>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { markdown_id: { eq: "sitemap-content" } } }
    ) {
      nodes {
        frontmatter {
          slug
          description
          keywords
          markdown_id
          title
          components_id
          definitions
          footnotes
          references
          interstitials {
            container
            trigger
            disclaimer
            setInner
            externalURL
            gtmlabel
          }
          isiToggle {
            container
            trigger
          }
        }
        id
        html
      }
    }
  }
`;

export default Sitemap;
